<template>
  <div class="flex flex-col items-center justify-start group relative border border-lightgray rounded-lg shadow-sm p-2.5 bg-white">
    <div class="relative">
      <div class="product-is-new z-20 m-2" v-if="selectedVariant?.isNew">
        {{ $t('app.new') }}
      </div>

      <div class="group">
        <div class="relative flex">
          <div class="relative overflow-hidden flex-1">
            <NuxtLinkLocale :to="selectedVariant?.url ? checkAndAddSlash(selectedVariant.url) : '#'" class="block bg-white">
              <div class="flex absolute z-20 justify-center text-center items-center w-full h-full" v-if="!selectedVariant?.inStock">
                <span class="product-out-of-stock"><StockOutText /></span>
              </div>

              <div class="flex absolute z-20 justify-center text-center items-end w-full h-full" v-if="selectedVariant?.isFreeShipping">
                <span class="p-1.5 text-sm bg-lightgray-50 w-full rounded-b-lg flex items-center justify-center">
                  <BoltIcon class="h-5 w-5 mr-2 fill-primary"/>
                  <span>{{ $t('app.free-shipping') }}</span>
                </span>
              </div>

              <div class="flex absolute z-20 justify-center text-center items-end right-0 top-0" v-if="selectedVariant?.qtySumForAllWarehouse > 0 && selectedVariant?.inStock">
                <span class="px-2 px-0.5 text-xs font-medium bg-green-600 text-white w-full flex items-center justify-center rounded">
                  <span>{{ selectedVariant?.qtySumForAllWarehouse }} {{ selectedVariant?.unitName }}</span>
                </span>
              </div>

              <NuxtImg
                  v-if="selectedVariant?.thumbnail"
                  :src="getImage(selectedVariant.thumbnail)"
                  width="800"
                  height="600"
                  fit="outside"
                  loading="lazy"
                  :placeholder="[50, 50, 93, 3]"
                  :quality="90"
                  :alt="selectedVariant?.title"
                  :class="{'product-img-out-of-stock' : !selectedVariant?.inStock}"
                  class="product-card-6-img"
              />

              <NuxtImg
                  v-else
                  fit="outside"
                  alt=""
                  class="z-1 rounded-lg object-contain aspect-square"
                  width="300"
                  height="300"
                  src="/images/no-image.png"
              />
            </NuxtLinkLocale>

            <!-- Ürün Seçenekleri -->
            <SectionProductCardGroupOptions :product="product" />

          </div>
        </div>

        <div class="flex flex-col text-base text-primary mt-1">
          <div class="font-bold text-sm text-secondary">
            {{ product.brandName }}
          </div>
          <div class="font-medium text-sm line-clamp-2">
            {{ selectedVariant?.title }}
          </div>
          <div class="text-sm text-gray-500">
            {{ product?.modelSku }}
          </div>
          <div class="flex items-center space-x-2.5 mt-1.5">

            <DiscountPercent :selected-variant="selectedVariant"/>

            <!-- Fiyat Bilgisi -->
            <SectionProductCardPriceView :variant="selectedVariant" />
          </div>

        </div>

        <FormButton
            color="primary"
            outline
            size="sm"
            type="button"
            class="w-full mt-2"
            @click="AddToCartInProductCard"
            :loading="cartLoading"
            v-if="selectedVariant?.inStock"
        >
          <LucideShoppingCart class="h-4 w-4 mr-2"/>
          {{ $t('app.add-to-cart') }}
        </FormButton>

      </div>
    </div>
  </div>
</template>

<script setup>
import {BoltIcon} from "@heroicons/vue/24/outline";

import {collect} from "collect.js";
import DiscountPercent from "~/components/DiscountPercent.vue";
import PriceUpdateModeDescriptionForB2B from "~/components/PriceUpdateModeDescriptionForB2B.vue";
import {customRound} from "~/composables/useHelper";
import {Warehouse} from "lucide-vue-next";

const props = defineProps(['product'])

const storeConfig = useStoreConfig()
const { baseConfig, showRateNextToPrice } = storeToRefs(storeConfig)

const {
  selectedWarehouse,
  hidePriceForSoldOut,
  showListPrice
} = useListing()

const {$listen} = useNuxtApp()

const {currentPage} = usePagination()

const isLoading = ref(false)
const cartLoading = ref(false)

const selectedVariant = useState(`selectedVariant::${props.product.productId}::${props.product.productGroupValue}`);

const isShowcaseMode = computed(() => unref(baseConfig)?.is_showcase_mode || unref(selectedVariant)?.isSaleable === 0)

const {addToBasket, addCartLoading, basketItems} = await useBasket()
const {isWholesaleUser, authModalIsVisible} = await useCustomer()
const {addWishlist} = useWishlist()

const cartQuantity = ref(0)
const quantityStep = computed(() => selectedVariant.value?.primaryStockData?.quantityStep)

const AddToCartInProductCard = async () => {
  cartLoading.value = true
  await addToBasket(selectedVariant.value.variantId, 1, selectedVariant.value?.primaryStockData.rawCurrency, selectedVariant.value?.selectedWarehouse, false, false)
      .then(() => cartLoading.value = false)
}

/**
 * Eğer ürünler sepette ekli ise, ekli olan ürünlerin sayfa yenileme gibi işlemlerde hatırlanması için kontrol sağlıyoruz
 */

watch([basketItems, currentPage], async ([cartVal, currentPageVal]) => {
  const cartQty = await collect(cartVal).where('variant_id', '=', selectedVariant.value.variantId).pluck('qty').first()
  if (cartQty) {
    cartQuantity.value = cartQty
  }
})

</script>

<style>
.card-button {
  @apply w-full flex text-sm bg-gray-100 items-center justify-center rounded-lg font-bold py-2 px-2 text-primary fill-primary hover:text-white hover:fill-white;
}
</style>